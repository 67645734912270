import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const InvisibleBoxSpacing = styled.div`
  height: 12px;
  width: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 0px;
  }
`;

export const SectionWrapper = styled.div<{ surface: 'dark' | 'light' }>`
  padding: 48px 0;
  background-color: ${(props) =>
    props.surface === 'dark'
      ? props.theme.palette.background.bg1
      : props.theme.palette.background.bg2};
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 20px 0 28px;
  }
`;

export const ProductSectionWrapper = styled.div<{ animationTrue?: boolean }>`
  max-width: 1232px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: ${(props) => (props.animationTrue ? '20px' : '24px')};
  }
`;

export const SectionTitleContainer = styled.div<{
  padding?: string | undefined;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: ${(props) => (props.padding ? props.padding : '0 16px')};
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 16px;
  }
`;

export const SectionTitle = styled.h2`
  font: 1.625rem/1.875rem Onest-SemiBold;
  color: ${(props) => props.theme.palette.text.t1};
  margin: 0;
  padding: 0;
  max-width: 1232px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    font: 1.25rem/1.5rem Onest-SemiBold;
  }
`;

export const SectionAction = styled.div<{
  status: 'everyTime' | 'conditional';
}>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  @media (max-width: ${WIDTH.mobileMax}) {
    display: none;
  }
  @media (min-width: ${WIDTH.desktopMin}) {
    display: ${(props) => (props.status === 'everyTime' ? 'flex' : 'none')};
  }
`;

export const ActionBtn = styled.div<{ disabled: boolean }>`
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  background-color: ${(props) =>
    props.theme.mode === 'dark' ? '#ffffff26' : '#00000012'};
  border-radius: 50%;
  transition: all 0.3s ease;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
    background-color: ${(props) =>
      props.theme.mode === 'dark' ? '#ffffff40' : '#0000001F'};
  }
  @media (min-width: ${WIDTH.desktopMin}) {
    height: 48px;
    width: 48px;
  }
`;

export const ViewAllBtn = styled.div<{ disabled: boolean }>`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 8px 24px;
  background-color: ${(props) =>
    props.theme.mode === 'dark' ? '#ffffff26' : '#00000012'};

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  transition: all 0.3s ease;
  p {
    text-transform: capitalize;
    font: 0.875rem/1.125rem Onest-Bold;
    color: ${(props) => props.theme.palette.text.t1};
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: flex;
  }

  :hover {
    cursor: pointer;
    transform: scale(1.02);
    background-color: ${(props) =>
      props.theme.mode === 'dark' ? '#ffffff40' : '#0000001F'};
    p {
      opacity: 1;
      color: ${(props) => props.theme.palette.text.t1};
    }
  }
`;

export const ProductSectionContainer = styled.div<{
  padding?: string | undefined;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.padding ? props.padding : '0 16px')};
  > div {
    > button {
      margin: auto;
      margin-top: 16px;
    }
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 0 0px;
    padding: 0 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0 0 0px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
  }
`;
export const ProductsSectionRowContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  flex: 1;
  overflow-x: auto;
  z-index: 1;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 16px;
    gap: 16px;
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    gap: 16px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 200px;
  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: 160px;
    min-width: 160px;
  }
`;

export const SmallCardSectionContainer = styled.div`
  display: flex;
  > div {
    > button {
      margin: auto;
      margin-top: 16px;
    }
  }
  padding: 0 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 0 0px;
    padding: 0 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0 0 0px;
  }
`;

export const SmallCardSectionRowContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
  grid-gap: 16px;
  width: 100%;

  @media (max-width: ${WIDTH.tabletMax}) {
    grid-template-columns: repeat(auto-fit, minmax(140px, auto));
  }
`;

export const GiftCardsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-grow: 1;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

export const PriceUnderSectionCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(179px, 1fr));
  position: relative;
  place-items: center;
  width: 100%;
  grid-gap: 16px;
  @media (max-width: ${WIDTH.mobileMax}) {
    grid-template-columns: 1fr 1fr;
    box-shadow: none;
    place-items: normal;
    grid-gap: 12px;
    padding: 0;
  }
`;

export const CategoryCardContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  gap: 16px;
`;

export const CategoryCardContainerAnimation = styled.div<{
  animationTrue?: boolean;
}>`
  display: flex;
  position: relative;
  width: 100%;
  gap: 16px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 12px;
    overflow: ${(props) => (props.animationTrue ? 'auto' : undefined)};
    padding: 0 16px;
  }
`;

export const CatWrapper = styled.div`
  overflow: auto;
  min-width: 110px;
  max-width: 110px;
  margin-top: 12px;
`;
export const SectionSideAction = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: calc(100% + 128px);
  height: 100%;
  left: -64px;
  position: absolute;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${WIDTH.laptopMax}) {
    display: none;
  }
`;

export const PlatformCardTile = styled.div<{
  bgColor: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.bgColor};
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  height: 110px;
  width: 210px;
`;
