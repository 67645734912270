import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const CategorySectionContainer = styled.div`
  // background-color: ${(props) => props.theme.palette.background.bg2};
`;

export const CategorySectionWrapper = styled.div`
  max-width: 1232px;
  margin: 0 auto 32px;
  margin-bottom: 0;
  padding: 0 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 0 24px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0 24px;
  }
`;

export const CategoriesSection = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(157px, 1fr));
  position: relative;
  place-items: center;
  // padding: 16px;
  width: 100%;
  grid-gap: 16px;
  // box-shadow: ${(props) => props.theme.shadows.s2};
  // background: ${(props) => props.theme.palette.background.bg1};
  // border-radius: ${(props) => props.theme.palette.borderRadius.b4};
`;

export const NextArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  z-index: 2;
  cursor: pointer;
  box-shadow: ${(props) => props.theme.shadows.s3};
  background: ${(props) => props.theme.palette.background.bg1};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px 0 0 8px;
  height: 72px;
  width: 36px;
  transition: 0.3s ease-in-out;
`;

export const PrevArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  left: 0;
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.bg1};
  box-shadow: ${(props) => props.theme.shadows.s3};
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;
  width: 36px;
  transition: 0.3s ease-in-out;
`;
