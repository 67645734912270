import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';
interface TileI {
  gradientColor: string;
}
export const Tile = styled.div<TileI>`
  aspect-ratio: 1/1;
  display: flex;
  min-width: 157px;
  width: 100%;
  height: 104px;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 16px 20px;
  background: ${(props) => props.theme.palette.button.grey.main};
  border-radius: 16px;
  opacity: 1;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.palette.border.b2};
  &:hover {
    // background: ${(props) => props.gradientColor};
    background: ${(props) => props.theme.palette.button.grey.hover};
    // div {
    //   color: ${COLORS.white};
    //   span {
    //     background: ${COLORS.white};
    //   }
    // }
    // span {
    //   // transition: 0.3s ease-in-out;
    //   transform: scale(1.1);
    // }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 16px 0px;
    max-height: 120px;
    height: auto;
    min-width: fit-content;
    width: 100%;
    box-shadow: ${(props) => props.theme.shadows.s1};
    margin: 0;
    div {
      span {
        height: 36px;
        width: 36px;
      }
    }
    &:hover {
      background: ${(props) => props.gradientColor};
      div {
        color: ${COLORS.white};
        span {
          background: ${COLORS.white};
        }
      }
      span {
        // transition: 0.3s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  @media (max-width: ${WIDTH.mobileMin}) {
    & div {
      font-size: 14px;
    }
  }
`;

export const TileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const IconContainer = styled.div<{ location?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => (props.location === 'store' ? '38px' : '38px')};
  width: ${(props) => (props.location === 'store' ? '38px' : '38px')};
  span {
    background: ${(props) => props.theme.palette.text.t1};
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 48px;
    width: 48px;
  }
`;

export const TileName = styled.div<{ location?: string }>`
  margin-top: ${(props) => (props.location === 'store' ? '4px' : '12px')};
  font-size: ${(props) => (props.location === 'store' ? '14px' : '1rem')};
  line-height: ${(props) => (props.location === 'store' ? '14px' : '1rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.text.t1};
`;
