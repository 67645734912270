import { MaskIcon } from './Icons';

export const exportCategoryIcon = (
  x: string,
  height: string,
  width: string,
  margin: string
) => {
  let url = '/icons/category-icons/action.svg';
  switch (x) {
    case 'Action':
      url = '/icons/category-icons/action.svg';
      break;
    case 'Adventure':
      url = '/icons/category-icons/adventure.svg';
      break;
    case 'Adult':
      url = '/icons/category-icons/adult.svg';
      break;
    case 'Anime':
      url = '/icons/category-icons/anime.svg';
      break;
    case 'Co-op':
      url = '/icons/category-icons/coop.svg';
      break;
    case 'Dating Simulator':
      url = '/icons/category-icons/dating-simulator.svg';
      break;
    case 'Fighting':
      url = '/icons/category-icons/fighting.svg';
      break;
    case 'FPS':
      url = '/icons/category-icons/fps.svg';
      break;
    case 'Hack and Slash':
      url = '/icons/category-icons/hack-and-slash.svg';
      break;
    case 'Horror':
      url = '/icons/category-icons/horror.svg';
      break;
    case 'Life Simulation':
      url = '/icons/category-icons/life-simulation.svg';
      break;
    case 'MMO':
      url = '/icons/category-icons/mmo.svg';
      break;
    case 'Open World':
      url = '/icons/category-icons/open-world.svg';
      break;
    case 'Platform':
      url = '/icons/category-icons/platform.svg';
      break;
    case 'Point & Click':
      url = '/icons/category-icons/point-and-click.svg';
      break;
    case 'Puzzle':
      url = '/icons/category-icons/puzzle.svg';
      break;
    case 'Racing':
      url = '/icons/category-icons/racing.svg';
      break;
    case 'RPG':
      url = '/icons/category-icons/rpg.svg';
      break;
    case 'Sport':
      url = '/icons/category-icons/sport.svg';
      break;
    case 'Strategy':
      url = '/icons/category-icons/strategy.svg';
      break;
    case 'Survival':
      url = '/icons/category-icons/survival.svg';
      break;
    case 'TPS':
      url = '/icons/category-icons/tps.svg';
      break;
    case 'Visual Novel':
      url = '/icons/category-icons/visual-novel.svg';
      break;
    case 'VR Games':
      url = '/icons/category-icons/vr-games.svg';
      break;
    default:
      url = '/icons/category-icons/action.svg';
      break;
  }
  return (
    <MaskIcon
      url={url}
      height={height}
      width={width}
      margin={margin}
      selected={true}
    />
  );
};
