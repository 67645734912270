import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

interface Props {
  type: 'light' | 'dark';
}

export const ProductSectionContainer = styled.div`
  // background-color: ${(props) => props.theme.palette.background.bg2};
`;

export const ProductSectionRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  grid-gap: 16px;

  @max-width: ${WIDTH.mobileMax} {
    grid-template-columns: repeat(auto-fit, minmax(140px, auto));
  }
  & > a {
    max-width: 200px;
  }
`;

export const ProductSectionWrapper = styled.div`
  max-width: 1232px;
  margin: 0 auto;
  padding: 0 16px 32px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  > div {
    > button {
      margin: auto;
      margin-top: 16px;
    }
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 0 0px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0 0 0px;
  }
`;

export const SliderContainer = styled.div`
  margin-top: 16px;
  // padding: 0 0 24px;
  padding-bottom: 16px;
  background: ${(props) => props.theme.palette.background.bg1};
  border-radius: ${(props) => props.theme.palette.borderRadius.b4};
  box-shadow: ${(props) => props.theme.shadows.s2};
  border: 1px solid ${(props) => props.theme.palette.divider.d1};
  & #home-page-section-slider .slick-slider {
    min-height: 360px;
  }
  & > section {
    padding: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @media (max-width: ${WIDTH.tabletMax}) {
    & > section {
      padding: 16px;
    }
  }
`;

export const ProductSectionTitle = styled.div`
  > div {
    font: 1.25rem/1.75rem Onest-Bold;
  }
  font: 1.25rem/1.75rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  display: flex;
  text-transform: capitalize;
  justify-content: space-between;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 1.125rem/1.5rem Onest-Bold;
  }
`;

export const SectionSub = styled.div`
  font-style: normal;
  font-family: Onest-SemiBold;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  flex: 1;
  justify-content: flex-end;
  color: ${COLORS.primary};
  &:hover {
    color: ${COLORS.primaryDark};
    transition: 0.3s all ease-in-out;
  }
`;

export const CardsSection = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-grow: 1;
  padding: 16px 0 0;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-thumb:hover {
    display: none;
  }
`;

export const SeeMoreButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NextArrowContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
  right: -0.3px;
  left: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const PrevArrowContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
  right: auto;
  left: -0.3px;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

interface ArrowWrapperProps {
  disabled?: boolean;
}

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  display: flex;
  place-items: center;
  place-content: center;
  background: ${(props) => props.theme.palette.background.bg3};
  height: 72px;
  width: 36px;
  border-radius: 8px 0 0 8px;
  box-shadow: ${(props) => props.theme.shadows.s3};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: 0.7s;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  & svg {
    stroke-width: 0.9;
  }
  &:active {
    background: ${(props) => props.theme.palette.background.bg2};
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    &:hover {
      transform: translateY(0px);
    }
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  flex: 1;
`;
