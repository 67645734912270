import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo } from 'react';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { saveEventV3 } from '../../utils/eventTracking';
import { WE_USER_EVENTS_CATEGORY_CLICKED } from '../../utils/we';
import {
  IconContainer,
  Tile,
  TileName,
  TileWrapper,
} from './CategoryTileStyle';
interface Props {
  category: {
    icon: JSX.Element;
    name: string;
    url: string;
    linearGradient: string;
  };
  location?: string;
}

const CategoryTile = (props: Props) => {
  const router = useRouter();
  const { cleverTap } = useAnalytics();

  return (
    <Tile gradientColor={props.category.linearGradient}>
      <Link href={props.category.url} passHref prefetch={false}>
        <a>
          <TileWrapper
            onClick={() => {
              WE_USER_EVENTS_CATEGORY_CLICKED(
                {
                  categoryName: props.category.name,
                  pageUrl: window.location.href,
                  link: props.category.url,
                },
                cleverTap
              );
              saveEventV3({
                category: 'collection_card',
                action: 'click',
                label: 'category_card',
                properties:
                  router.pathname === '/store'
                    ? (router.query.group as string) ?? ''
                    : 'collection',
                value: [props.category.name],
                from: router,
              });
            }}
          >
            <IconContainer location={props.location}>
              {props.category.icon}
            </IconContainer>
            <TileName location={props.location}>{props.category.name}</TileName>
          </TileWrapper>
        </a>
      </Link>
    </Tile>
  );
};

export default memo(CategoryTile);
