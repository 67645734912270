import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useAnalytics } from '../../../../contexts/AnalyticsProvider';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { getRegionFromRegionId } from '../../../../helper/helper';
import { HomepageCollectionsProductCardInterface } from '../../../../Interface/HomepageCollectionsInterface';
import {
  ProductCardInterface,
  ProductNewI,
  ProductSectionCardInterface,
} from '../../../../Interface/ProductCardInterface';
import { saveEventV3 } from '../../../../utils/eventTracking';
import { getProductSkuFromSlug } from '../../../../utils/getProductSkuFromSlug';
import { saveSelectedRecommendations } from '../../../../utils/recombee';
import { WE_USER_EVENT_COLLECTION_PRODUCT_CLICKED } from '../../../../utils/we';
import ProductCardG from '../../../ProductCard/ProductCardG';
import {
  ProductSectionContainer,
  ProductSectionWrapper,
  ProductsSectionRowContainer,
  SectionTitle,
  SectionTitleContainer,
  ViewAllBtn,
} from '../../HomePageV1Style';
import ProductCardsClusterSideActions from './ProductCardsClusterSideActions';
import ProductCardsClusterTitleActions from './ProductCardsClusterTitleActions';

interface Props {
  title: string;
  products:
    | Array<ProductSectionCardInterface>
    | Array<HomepageCollectionsProductCardInterface>
    | Array<ProductCardInterface>
    | Array<ProductNewI>;
  titleName: string;
  collectionLink?: string;
  arrowPosition?: 'header' | 'body';
  padding?: string;
  recommendationId?: string;
  hideOOSProducts?: boolean;
}

function ProductCardsCluster({
  title,
  products,
  titleName,
  collectionLink,
  arrowPosition,
  padding,
  recommendationId,
  hideOOSProducts = false,
}: Props) {
  const theme = useTheme();
  const router = useRouter();
  const { messages } = useLocale();
  const { view_all_msg } = messages || {};
  const { cleverTap } = useAnalytics();

  const sliderRef = useRef<null | HTMLDivElement>(null);

  const [scrollButtonDisable, setScrollButtonDisable] = useState<
    'prev' | 'next' | null
  >('prev');

  const handleScrollClick = useCallback((dir: 'prev' | 'next') => {
    if (sliderRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = sliderRef.current;
      const scrollAmount = clientWidth;

      if (dir === 'next') {
        sliderRef.current.scrollTo({
          left: Math.min(scrollLeft + scrollAmount, scrollWidth - clientWidth),
          behavior: 'smooth',
        });
      } else {
        sliderRef.current.scrollTo({
          left: Math.max(scrollLeft - scrollAmount, 0),
          behavior: 'smooth',
        });
      }
    }
  }, []);

  const handleScroll = (e: any) => {
    const { target } = e;
    const scrollableWidth = target.scrollWidth - target.clientWidth;

    if (target.scrollLeft === 0) {
      setScrollButtonDisable('prev');
    } else if (target.scrollLeft >= scrollableWidth) {
      setScrollButtonDisable('next');
    } else {
      setScrollButtonDisable(null);
    }
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener('scroll', handleScroll);
      handleScroll({ target: slider }); // Initial check
    }

    return () => {
      if (slider) {
        slider.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleProductClick = useCallback(
    (prod, index) => {
      if (getProductSkuFromSlug(prod.slug) && recommendationId) {
        saveSelectedRecommendations(
          getProductSkuFromSlug(prod.slug) as string,
          recommendationId
        );
      }
      WE_USER_EVENT_COLLECTION_PRODUCT_CLICKED(
        {
          collectionName: titleName,
          pageUrl: window.location.href,
          productName: prod.title,
          productSlug: prod.slug,
          basePrice: prod.price ? prod.price.toString() : '',
          productPlatform: prod.platform as string,
          discount:
            prod && Number(prod.mrp) > 0
              ? Math.round(
                  ((Number(prod.mrp) - Number(prod.price)) * 100) /
                    Number(prod.mrp)
                )
              : 0,
          productImage: prod.image,
          productRegion: getRegionFromRegionId(
            (prod.regionId as string).toString()
          ),
        },
        cleverTap
      );

      saveEventV3({
        category: 'product_card',
        action: 'click',
        label: titleName,
        properties: 'product',
        value: [prod.slug],
        from: router,
      });
    },
    [cleverTap, recommendationId, router, titleName]
  );

  const hasPrice = (product: any): product is ProductCardInterface => {
    return product && typeof product.price === 'number' && product.price > 0;
  };

  const filteredProducts: Array<ProductCardInterface> = hideOOSProducts
    ? (products as Array<ProductCardInterface>).filter(hasPrice)
    : (products as Array<ProductCardInterface>);

  return (
    <ProductSectionWrapper>
      <SectionTitleContainer padding={padding}>
        <SectionTitle>{title}</SectionTitle>
        {arrowPosition !== 'body' ? (
          <ProductCardsClusterTitleActions
            handleScrollClick={handleScrollClick}
            scrollButtonDisable={scrollButtonDisable}
            status={arrowPosition === 'header' ? 'everyTime' : 'conditional'}
          />
        ) : null}
      </SectionTitleContainer>

      <ProductSectionContainer padding={padding}>
        {arrowPosition !== 'header' ? (
          <ProductCardsClusterSideActions
            handleScrollClick={handleScrollClick}
            scrollButtonDisable={scrollButtonDisable}
          />
        ) : null}
        <ProductsSectionRowContainer ref={sliderRef} onScroll={handleScroll}>
          {filteredProducts?.slice(0, 10)?.map((product, index) => {
            return (
              <ProductCardG
                key={index}
                product={product}
                widthType="fixed"
                clickBubble={() => handleProductClick(product, index)}
              />
            );
          })}
        </ProductsSectionRowContainer>
      </ProductSectionContainer>
      {!collectionLink ? null : (
        <Link href={collectionLink} passHref prefetch={false}>
          <a style={{ width: 'fit-content', margin: '0 auto' }}>
            <ViewAllBtn
              disabled={false}
              onClick={() => {
                saveEventV3({
                  action: 'click',
                  category: 'collection_card',
                  from: router,
                  label: 'view_collection',
                  properties: collectionLink?.split('/')?.pop() ?? '',
                  value: [],
                });
              }}
            >
              <p>Show all</p>
            </ViewAllBtn>
          </a>
        </Link>
      )}
    </ProductSectionWrapper>
  );
}

export default memo(ProductCardsCluster);
