import CategoryTile from '../CategoryTile/CategoryTile';
import {
  CategoriesSection,
  CategorySectionContainer,
  CategorySectionWrapper,
} from './CategorySectionStyle';

import { MessageKeyTypes } from '../../Interface/MessageKeyTypes';
import { useLocale } from '../../contexts/LocalizationContext';
import { exportCategoryIcon } from '../Icons/CategoryIcons';
import { ProductSectionTitle } from '../ProductSection/ProductSectionNewStyle';

const gradientColors = {
  red: 'linear-gradient(237.06deg, #F65673 39.87%, #E00029 98.56%)',
  orange: 'linear-gradient(237.06deg, #F6A656 39.87%, #DC6107 98.56%)',
  purple: 'linear-gradient(236.46deg, #94A5FF 9.79%, #1E3EE4 95.95%)',
  blue: 'linear-gradient(235.37deg, #56D9F6 31.43%, #0024E0 131.44%)',
  green: 'linear-gradient(237.06deg, #3ADE00 36.76%, #007513 98.56%)',
  pink: 'linear-gradient(237.06deg, #D975FC 39.87%, #AD09C8 98.56%)',
};

export const generateCategoriesList = (
  messages: { [key in MessageKeyTypes]: string } | undefined
) => {
  const {
    action_msg,
    adventure_msg,
    fighting_msg,
    fps_msg,
    simulation_msg,
    rpg_msg,
    mmo_msg,
    strategy_msg,
    sports_msg,
    tps_msg,
    platformer_msg,
    platform_msg,
  } = messages || {};
  return [
    {
      name: action_msg ?? '',
      url: '/store/genre/action',
      icon: exportCategoryIcon('Action', '38px', '38px', '0'),
      linearGradient: gradientColors.red,
    },
    // {
    //   name: 'Adult',
    //   url: '/store/genre/adult-games',
    //   icon: exportCategoryIcon('Adult', '38px', '38px', '0'),
    // },
    {
      name: adventure_msg ?? '',
      url: '/store/genre/adventure',
      icon: exportCategoryIcon('Adventure', '38px', '38px', '0'),
      linearGradient: gradientColors.green,
    },
    // {
    //   name: 'Anime',
    //   url: '/store/genre/anime',
    //   icon: exportCategoryIcon('Anime', '38px', '38px', '0'),
    // },
    // {
    //   name: 'Co-op',
    //   url: '/store/genre/co-op',
    //   icon: exportCategoryIcon('Co-op', '38px', '38px', '0'),
    // },
    // {
    //   name: 'Dating Simulator',
    //   url: '/store/genre/dating-simulator',
    //   icon: exportCategoryIcon('Dating Simulator', '38px', '38px', '0'),
    // },
    {
      name: fighting_msg ?? '',
      url: '/store/genre/fighting',
      icon: exportCategoryIcon('Fighting', '38px', '38px', '0'),
      linearGradient: gradientColors.blue,
    },
    {
      name: fps_msg ?? '',
      url: '/store/genre/fps',
      icon: exportCategoryIcon('FPS', '38px', '38px', '0'),
      linearGradient: gradientColors.purple,
    },
    // {
    //   name: 'Hack & Slash',
    //   url: '/store/genre/hack-and-slash',
    //   icon: exportCategoryIcon('Hack and Slash', '38px', '38px', '0'),
    // },
    // {
    //   name: 'Horror',
    //   url: '/store/genre/horror',
    //   icon: exportCategoryIcon('Horror', '38px', '38px', '0'),
    // },
    {
      name: simulation_msg ?? '',
      url: '/store/genre/simulation',
      icon: exportCategoryIcon('Life Simulation', '38px', '38px', '0'),
      linearGradient: gradientColors.pink,
    },
    {
      name: mmo_msg ?? '',
      url: '/store/genre/mmo',
      icon: exportCategoryIcon('MMO', '38px', '38px', '0'),
      linearGradient: gradientColors.purple,
    },
    // {
    //   name: 'Open World',
    //   url: '/store/genre/open-world',
    //   icon: exportCategoryIcon('Open World', '38px', '38px', '0'),
    // },
    {
      name: platformer_msg ?? '',
      url: '/store/genre/platformer',
      icon: exportCategoryIcon('Platform', '38px', '38px', '0'),
      linearGradient: gradientColors.green,
    },
    {
      name: 'Point & Click',
      url: '/store/genre/point-and-click',
      icon: exportCategoryIcon('Point & Click', '38px', '38px', '0'),
      linearGradient:
        'linear-gradient(237.06deg, #D975FC 39.87%, #AD09C8 98.56%)',
    },
    {
      name: 'Puzzle',
      url: '/store/genre/puzzle',
      icon: exportCategoryIcon('Puzzle', '38px', '38px', '0'),
      linearGradient: gradientColors.blue,
    },
    {
      name: 'Racing',
      url: '/store/genre/racing',
      icon: exportCategoryIcon('Racing', '38px', '38px', '0'),
      linearGradient: gradientColors.pink,
    },
    {
      name: rpg_msg ?? '',
      url: '/store/genre/rpg',
      icon: exportCategoryIcon('RPG', '38px', '38px', '0'),
      linearGradient: gradientColors.red,
    },
    {
      name: strategy_msg ?? '',
      url: '/store/genre/strategy',
      icon: exportCategoryIcon('Strategy', '38px', '38px', '0'),
      linearGradient: gradientColors.green,
    },
    {
      name: sports_msg ?? '',
      url: '/store/genre/sports',
      icon: exportCategoryIcon('Sport', '38px', '38px', '0'),
      linearGradient: gradientColors.blue,
    },
    // {
    //   name: 'Survival',
    //   url: '/store/genre/survival',
    //   icon: exportCategoryIcon('Survival', '38px', '38px', '0'),
    // },
    {
      name: tps_msg ?? '',
      url: '/store/genre/tps',
      icon: exportCategoryIcon('TPS', '38px', '38px', '0'),
      linearGradient:
        ' linear-gradient(237.06deg, #F65673 39.87%, #E00029 98.56%)',
    },
    // {
    //   name: 'Visual Novel',
    //   url: '/store/genre/visual-novel',
    //   icon: exportCategoryIcon('Visual Novel', '38px', '38px', '0'),
    // },
    // {
    //   name: 'VR Games',
    //   url: '/store/genre/vr-games',
    //   icon: exportCategoryIcon('VR Games', '38px', '38px', '0'),
    // },
  ];
};

interface CategorySectionProps {
  color: 'dark' | 'light';
}

const CategorySection = (props: CategorySectionProps) => {
  const { messages } = useLocale();

  const { discover_games_by_category_msg } = messages || {};

  const category_list = generateCategoriesList(messages);

  return (
    <CategorySectionContainer>
      <CategorySectionWrapper>
        <ProductSectionTitle>
          {discover_games_by_category_msg}
        </ProductSectionTitle>
        <section>
          <CategoriesSection id="categories-slider">
            {category_list.map((category, index) => (
              <CategoryTile key={index} category={category} />
            ))}
          </CategoriesSection>
        </section>
      </CategorySectionWrapper>
    </CategorySectionContainer>
  );
};

export default CategorySection;
